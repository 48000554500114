
// Runtime language loading: https://github.com/stefan-schweiger/ng-runtime-i18n
export const localeLocalStorageKey = 'tl-app-locale';
export const timezoneLocalStorageKey = 'tl-app-timezone';
export const getLocale = (): string => {
  const availableLocales = ['en-US', 'de-DE'];

  // check the url path include the locale
  let locale = window.location.pathname.match(/^\/([A-z-]{5})(\/|$)/)?.[1];

  if (!locale) {
    locale =
      // if the url does not include a locale, try to get it from the localStorage
      localStorage.getItem(localeLocalStorageKey)
      // if nothing is stored get the best language as specified by the browser settings. Not supported for now
      // ?? availableLocales.find((l) => navigator.language.startsWith(l))
      // else just fallback to the default language (en-US in this case)
      ?? availableLocales[0];
  }

  return locale;
};

export const setLocale = (locale: string): void => {
  localStorage.setItem(localeLocalStorageKey, locale);
  window.document.documentElement.lang = locale;
};
