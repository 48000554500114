import { EnvironmentModel } from '@root/environments/environment.model';

export const environment: EnvironmentModel = {
  production: true,
  use_custom_error_handler: true,
  enable_raygun: true,
  raygun_identifier: 'dev1',
  raygun_api_key: 'etjpgKhgjBXUIfb5edpoLQ',
  BASE_URL: 'https://app-dev1.thetalake.com',
  API_URL: 'https://app-dev1.thetalake.com/api/v1',
  INGESTER_API_URL: 'https://ingesterapi-dev1.thetalake.com/api/v1',
  ACTION_CABLE_URL: 'wss://app-dev1.thetalake.com/cable',
  // eslint-disable-next-line max-len
  GOJS_LICENSE: '298647e0b3624fc702d90676423d6bbc5cf07e34c8960ef7590014f4ef586e40219bb87801dadcc5d5aa4af8487b92d9d5d7792fc31c503ae262d4884ae281f8e53e21b71300108af00571c39dfd29a3f52f74f691e222a1da6a9cf4bef8c59c0eb8f2c658c90fb82c670f2e5578',
  PENDO_ENABLED: true,
  CHURN_ZERO_ENABLED: true,
  CHURN_ZERO_APP_KEY: '1!mxilbpbxLMxC06ALHS93ATj8RlwMh05pEipUHQEzaQst13B7',
  CHURN_ZERO_URL: 'https://thetalake-dev.us1app.churnzero.net/churnzero.js'
};
